<template>
  <div class="uploadvideo">
    <el-upload
      class="upload-demo"
      drag
      action=""
      multiple
      :before-upload="beforeupload"
      :http-request="uploadhttp"
      :before-remove="removes"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        只能上传视频文件，且不超过80M！(仅支持 MP4 / WMV 格式)
      </div>
    </el-upload>
    <el-progress
      v-if="progresstype"
      class="videojdt"
      :text-inside="true"
      :stroke-width="20"
      :percentage="percentage"
      status="success"
    ></el-progress>
  </div>
</template>
<script>
export default {
  props: ["videoall"],
  data() {
    return {
      playsinline: true,
      videourl: "",
      userinfo: {},
      percentage: 0,
      progresstype: false,
    };
  },
  methods: {
    beforeupload(file) {
      let that = this;
      let isJPG =
        file.type === "video/mp4" ||
        file.type === "video/x-ms-wmv"
      let isLt1M = file.size / 1024 / 1024 < 80;
      if (!isJPG) {
        that.$message.error("请上传视频文件！");
        return isJPG;
      }
      if (!isLt1M) {
        that.$message.error("上传视频大小不能超过 80MB!");
        return isLt1M;
      }
      return isJPG && isLt1M;
    },
    removes(file, fileList) {
      // console.log(file,fileList);
      // return false;
    },
    uploadhttp(e) {
      let that = this;
      let file = new FormData();
      file.append("file", e.file);
      // file.append("d_id", that.videoall);
      // file.append("d_id", "18");
      this.$axios({
        headers: {
          "X-CSRF-TOKEN": that.userinfo.token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: "/api/UploadFile/upVideo",
        method: "post",
        data:file,
        onUploadProgress: (progressEvent) => {
          this.progresstype = true;
          let total = progressEvent.total; //总的大小
          var loaded = progressEvent.loaded; //已上传的大小
          if (total == loaded) {
            that.$message.success("视频转码中，请稍等！");
          }
          that.percentage = Math.ceil(loaded / (total / 100));
          if (that.percentage > 90) {
            that.percentage = 96;
          }
        },
      }).then((res) => {
        console.log(res.data);
        if (res.data.code == 1) {
          that.percentage = 100;
          that.progresstype = false;
          that.$message.success(res.data.messages);
          that.$emit("uploadvideo", res.data.result);
          that.videourl = res.data.result;
        }
      });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
  },
};
</script>