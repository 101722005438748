<template>
  <div class="Userhl_shangchuan">
    <div class="fxiangmu_top">
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">作品名称</div>
          <div class="fxiangmu_toplir">
            <el-input v-model="wk_name" placeholder="请输入作品名称"></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">真实姓名</div>
          <div class="fxiangmu_toplir">
            <el-input
              v-model="wk_zhenshiname"
              placeholder="请输入真实姓名"
            ></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">作品类型</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="radio_type" label="1">成果转化</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="radio_type" label="2">线上展览</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox" v-if="zhanlantype">
              <el-button :type="bsbtn_type" @click="zhanlanbtn_type(1)"
                >毕业设计展</el-button
              >
            </div>
            <div class="fxiangmu_toplir_weituobox" v-if="zhanlantype">
              <el-button :type="sjbtn_type" @click="zhanlanbtn_type(2)"
                >作品展览</el-button
              >
            </div>
          </div>
        </li>
        <!-- 原创设计独有 出售 -->
        <li class="fxiangmu_topli" v-if="yuanchuangtype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">是否出售</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="radio_money" label="0">否</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox">
              <el-radio v-model="radio_money" label="1">是</el-radio>
            </div>
            <div class="fxiangmu_toplir_weituobox" v-if="moneytype">
              <span>金额</span>
            </div>
            <div
              class="fxiangmu_toplir_weituobox fxiangmu_toplir_weituobox_tfshouinp"
              v-if="moneytype"
            >
              <el-input v-model="money" placeholder="请输入金额"></el-input>
            </div>
          </div>
        </li>
        <!-- 原创设计独有 完 ！ -->

        <!-- 毕设站独有 展览类型 院校名称 指导老师 -->
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览类型</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <el-select v-model="rel_type" placeholder="请选择展览类型">
              <el-option
                v-for="item in rel_typeall"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">院校名称</div>
          <div class="fxiangmu_toplir" style="position: relative">
            <el-input
              @focus="schoolfocus"
              v-model="school"
              placeholder="请输入院校名称搜索院校"
            ></el-input>
            <ul class="schoollb" v-show="schoollbtype">
              <li
                v-for="(item, index) in schoollbli"
                :key="index"
                @click="schoollbliclick(item.name, item.id)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览名称</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <el-select v-model="school_zlname" placeholder="请选择毕设展名称">
              <el-option
                v-for="item in school_zlall"
                :key="item.d_id"
                :label="item.title"
                :value="item.d_id"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">班级选择</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="banjioptionsval" placeholder="请选择所在班级">
              <el-option
                v-for="(item, index) in banjioptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">指导老师</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <div class="fxiangmu_toplir_weituobox">
              <el-input
                v-model="teachername"
                placeholder="请输入指导老师姓名"
              ></el-input>
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">专业名称</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <el-select v-model="zhuanyename" placeholder="请选择专业名称">
              <el-option
                v-for="(item, index) in zhuanyeoptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil"></div>
          <div class="fxiangmu_toplic">学号</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_weituo">
            <el-input v-model="xuehaoval" placeholder="请输入学号"></el-input>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="bishezhantype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">学历选择</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="xuelioptionsval" placeholder="请选择学历">
              <el-option
                v-for="item in xuelioptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </li>

        <!-- 毕设站独有  完 ！ -->

        <!-- 作品展览独有 展览名称 专业名称 -->
        <li class="fxiangmu_topli" v-if="shejizl">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">展览名称</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="school_zlname" placeholder="请选择展览名称">
              <el-option
                v-for="item in zhanlanoptions"
                :key="item.d_id"
                :label="item.title"
                :value="item.d_id"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="shejizl">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic fxiangmu_toplir_weituoboxzyios">
            专业名称
          </div>
          <div class="fxiangmu_toplir">
            <el-select v-model="zhuanyename" placeholder="请选择专业名称">
              <el-option
                v-for="(item, index) in zhuanyeoptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <!-- 作品展览独有 完 ！ -->

        <!-- 原创设计 -->
        <li class="fxiangmu_topli" v-if="yuanchuangtype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">专业分类</div>
          <div class="fxiangmu_toplir">
            <el-select v-model="zhuanyeval" placeholder="请选择专业分类">
              <el-option
                v-for="item in zyoptions"
                :key="item.classid"
                :label="item.classname"
                :value="item.classname"
              >
              </el-option>
              <el-option label="其他" value="0"> </el-option>
            </el-select>
            <el-select
              style="margin-left: 2rem"
              v-model="zhuanyeval_sm"
              placeholder="请选择专业细分"
              v-if="zhuanyeval_smtype"
            >
              <el-option
                v-for="item in zyoptionssmall"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </div>
        </li>
        <!-- 原创设计完 ！-->
      </ul>
    </div>
    <div class="fxiangmu_top">
      <ul class="fxiangmu_topul">
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">作品简介</div>
          <div class="fxiangmu_toplir">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入作品简介"
              v-model="textareaall"
            >
            </el-input>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">上传封面</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <img :src="fmimg" alt="" />
            <el-button plain icon="el-icon-picture">
              {{ fengmiantext }}
              <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
            </el-button>
            <p class="uploadimgtext">
              建议上传20MB以内的图片(仅支持jpg、png、jpeg格式)
            </p>
            <p></p>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">上传作品集</div>
          <div class="fxiangmu_toplir fxiangmu_toplirimgbtn">
            <el-button plain icon="el-icon-picture" class="upimgduo">
              立即上传
              <Uploadimg :imgall="imgallsfalse" @uploadimgheji="imghejiarr" />
            </el-button>
            <p class="uploadimgtext">
              建议上传20MB以内的图片(仅支持jpg、png、gif、jpeg格式)
            </p>
            <ul class="uptujiul">
              <li
                v-for="(item, index) in imghejidata"
                :key="index"
                v-show="item.src != ''"
              >
                <div class="uptujiulremove" @click="tujiremove(index)">
                  删除
                </div>
                <img :src="item.src" :alt="item.name" />
              </li>
            </ul>
          </div>
        </li>
        <li class="fxiangmu_topli" v-if="ziptype">
          <div class="fxiangmu_toplil">*</div>
          <div class="fxiangmu_toplic">上传附件</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_rwsbtnbox">
            <div v-if="uploadziptype">
              <Uploadzip @uploadzip="uploadzipurl" />
            </div>
            <div v-if="uploadziptype_f" class="fxiangmu_toplir_weituo">
              <div class="fxiangmu_toplir_weituobox">
                <el-button disabled>上传完成！</el-button>
              </div>
              <div class="fxiangmu_toplir_weituobox">
                <el-button plain @click="uploadzipurl_again"
                  >重新上传附件</el-button
                >
              </div>
            </div>
            <div
              class="fxiangmu_toplir_weituobox fxiangmu_toplir_weituoboxzyios"
              v-if="wangpanziptp"
            >
              网盘地址
            </div>
            <div class="fxiangmu_toplir_weituobox" v-if="wangpanziptp">
              <el-input
                v-model="baiduzip"
                placeholder="请输入网盘地址"
              ></el-input>
            </div>
            <div
              class="fxiangmu_toplir_weituobox fxiangmu_toplir_weituoboxzyios"
              v-if="wangpanziptp"
            >
              提取码
            </div>
            <div class="fxiangmu_toplir_weituobox" v-if="wangpanziptp">
              <el-input
                v-model="baiduzipma"
                placeholder="请输入提取码"
              ></el-input>
            </div>
            <div class="fxiangmu_toplir_weituobox" v-if="wangpanziptp">
              <el-button
                plain
                style="height: 40px; line-height: 40px; padding: 0 20px"
                @click="wangpanziptpclick"
                >重新上传附件</el-button
              >
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil"></div>
          <div class="fxiangmu_toplic">上传视频</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_rwsbtnbox">
            <div v-if="uploadvideotype">
              <Uploadvideo
                @uploadvideo="uploadvideourl"
                :videoall="school_zlname"
              />
            </div>
            <div v-if="uploadvideotype_f" class="fxiangmu_toplir_weituo">
              <div class="fxiangmu_toplir_weituobox">
                <el-button disabled>上传完成！</el-button>
              </div>
              <div class="fxiangmu_toplir_weituobox">
                <el-button type="danger" @click="uploadvideourl_again" plain
                  >删除</el-button
                >
              </div>
              <div class="fxiangmu_toplir_weituobox">
                <el-button plain @click="uploadvideourl_again"
                  >重新上传视频</el-button
                >
              </div>
            </div>
          </div>
        </li>
        <li class="fxiangmu_topli">
          <div class="fxiangmu_toplil"></div>
          <div class="fxiangmu_toplic">上传音频</div>
          <div class="fxiangmu_toplir fxiangmu_toplir_rwsbtnbox">
            <div v-if="uploadmp3type">
              <Uploadmp3 @uploadmp3="uploadmp3url" />
            </div>
            <div v-if="uploadmp3type_f" class="fxiangmu_toplir_weituo">
              <div class="fxiangmu_toplir_weituobox">
                <el-button disabled>上传完成！</el-button>
              </div>
              <div class="fxiangmu_toplir_weituobox">
                <el-button type="danger" @click="uploadmp3url_again" plain
                  >删除</el-button
                >
              </div>
              <div class="fxiangmu_toplir_weituobox">
                <el-button plain @click="uploadmp3url_again"
                  >重新上传音频</el-button
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <el-button
      class="xiangmusub_btn"
      type="primary"
      :disabled="sbdisabled"
      plain
      @click="subwk"
      >发布作品</el-button
    >
    <el-dialog title="提示" :visible.sync="yasuobaotp" width="30%">
      <div class="baiduzipbox">
        <div class="baiduzipboxspan">
          文件过大时，请上传至百度网盘！
          <a target="_blank" href="https://pan.baidu.com/"
            >（ 立即前往百度网盘 ）</a
          >
        </div>
        <el-input
          v-model="baiduzip"
          placeholder="请输入百度网盘地址"
        ></el-input>
        <el-input v-model="baiduzipma" placeholder="请输入提取码"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="yasuobaotp = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            yasuobaotp = false;
            baiduzipif();
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Uploadzip from "../Uploadzip";
import Uploadimg from "@/components/Uploadimg.vue";
import Uploadvideo from "@/components/Uploadvideo.vue";
import Uploadmp3 from "@/components/Uploadmp3.vue";
export default {
  components: { Uploadzip, Uploadimg, Uploadvideo, Uploadmp3 },
  data() {
    return {
      sbdisabled: false,
      fengmiantext: "上传封面",
      yasuobaotp: false,
      baiduzip: "",
      baiduzipma: "",
      wangpanziptp: false,
      imgalls: {
        name: "",
        width: 400,
        height: 270,
      },
      imgallsfalse: {
        name: "complex",
        width: 200,
        height: 200,
        d_id: "",
      },
      zhuanyeval_sm: "",
      zhuanyeval_smtype: false,
      zhanlanoptions: [],
      school_zlname: "",
      school_zlall: [],
      xuehaoval: "",
      schoolid: "",
      schoolhidden: 0,
      schoollbtype: false,
      schoollbli: [],
      wk_name: "", //作品名称
      money: 0, //原创设计价格
      fmimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png", //图片地址
      textareaall: "", //作品简介
      uploadzipurls: "", //压缩包地址
      school: "", //学校名称
      teachername: "", //指导老师
      zhuanyename: "", //专业名称
      zhuanyeval: "", //行业领域
      zhanlanname: "", //展览名称
      tfdesign: 1, //1 毕业设计 2 作品展览
      video: "", //视频
      rel_type: "2", //1 年度 2 学校
      d_id: 0, //展览标题id
      radio_type: "2",
      radio_money: "0",
      moneytype: false,
      yuanchuangtype: false,
      zhanlantype: true,
      bishezhantype: true,
      ziptype: false,
      bsbtn_type: "primary",
      sjbtn_type: "",
      options: [],
      rel_typeall: [
        //1 年度 2 学校
        {
          label: "年度",
          value: "1",
          disabled: true,
        },
        {
          label: "院校",
          value: "2",
          disabled: false,
        },
      ],
      schoolall: [
        {
          label: "院校1",
          value: "1",
        },
        {
          label: "院校2",
          value: "2",
        },
      ],
      xuelioptionsval: "",
      xuelioptions: [
        {
          label: "专科",
          value: "1",
        },
        {
          label: "本科",
          value: "2",
        },
        {
          label: "硕士",
          value: "3",
        },
      ],
      banjioptions: [],
      banjioptionsval: "",
      zhuanyeoptions: [],

      uploadziptype: true,
      uploadziptype_f: false,
      uploadvideotype: true,
      uploadvideotype_f: false,
      uploadmp3type: true,
      uploadmp3type_f: false,

      shejizl: false,
      userinfo: {},
      zyoptions: [],
      zyoptionssmall: [],
      imghejidata: [],
      mp3: "", //音频
      wk_zhenshiname: "", //毕设展真实姓名
      bianjinum: "0",
      resultbj: {}, //编辑数据
    };
  },
  methods: {
    uploadzipurl_again() {
      this.uploadzipurls = "";
    },
    uploadvideourl_again() {
      this.video = "";
    },
    uploadmp3url_again() {
      this.mp3 = "";
    },
    uploadzipurl(url) {
      if (url == "0") {
        this.yasuobaotp = true;
      } else {
        this.uploadzipurls = url;
      }
    },
    imghejiarr(url) {
      this.imghejidata.push(url);
    },
    tujiremove(indexs) {
      this.imghejidata.splice(indexs, 1);
    },
    uploadvideourl(url) {
      this.video = url;
    },
    uploadmp3url(url) {
      this.mp3 = url;
    },
    uploadimgurl(rul) {
      this.fmimg = rul;
    },
    zhanlanbtn_type(id) {
      var that = this;
      that.tfdesign = id;
      if (id == 1) {
        that.bsbtn_type = "primary";
        that.sjbtn_type = "";
      } else {
        that.bsbtn_type = "";
        that.sjbtn_type = "primary";
      }
    },
    subwk() {
      this.sbdisabled = true;
      var that = this;
      var atlas_img = "";
      for (var i = 0; i < this.imghejidata.length; i++) {
        atlas_img = atlas_img + this.imghejidata[i].src + ",";
      }
      if (that.wk_name == "") {
        that.$message("作品名称为必填项！");
        this.sbdisabled = false;
        return false;
      }
      if (that.textareaall == "") {
        that.$message("请输入作品简介！");
        this.sbdisabled = false;
        return false;
      }
      if (this.radio_money == "1") {
        if (
          that.uploadzipurls == "" ||
          that.baiduzip == "" ||
          that.baiduzipma == ""
        ) {
          if (that.baiduzipma == "" || that.baiduzip == "") {
            if (that.uploadzipurls == "") {
              that.$message("出售作品 附件为必选项！");
              this.sbdisabled = false;
              return false;
            }
          }
          if (that.uploadzipurls == "") {
            if (that.baiduzipma == "" || that.baiduzip == "") {
              that.$message("出售作品 附件为必选项！");
              this.sbdisabled = false;
              return false;
            }
          }
        }
      }
      // if (that.radio_type == "2" && that.tfdesign == "1") {
      //   var a = that.banjioptionsval;
      //   var b = that.xuehaoval;
      //   var str = b.replace(a, "");
      //   console.log(str);
      //   if (str == b) {
      //     that.$message("请核对班级及学号！");
      //     this.sbdisabled = false;
      //     return false;
      //   }
      // }
      if (
        that.fmimg ==
        "https://task.hozoin.cn/Works/20201104/sj-4369072618.png"
      ) {
        that.$message("请上传作品封面");
        this.sbdisabled = false;
        return false;
      }
      if (atlas_img == "") {
        that.$message("请上传作品图集");
        this.sbdisabled = false;
        return false;
      }
      if (that.radio_type == 1) {
      } else {
        if (that.tfdesign == "1") {
          // 毕设展必填
          if (that.schoolid == "") {
            that.$message("请输入学校名称");
            this.sbdisabled = false;
            return false;
          }
          if (that.school_zlname == "") {
            that.$message("请选择展览名称");
            this.sbdisabled = false;
            return false;
          }
          console.log(that.banjioptionsval)
          if (that.banjioptionsval == "") {
            that.$message("请选择班级");
            this.sbdisabled = false;
            return false;
          }
          
          if (that.teachername == "") {
            that.$message("请输入指导老师");
            this.sbdisabled = false;
            return false;
          }
          
          if (that.zhuanyename == "") {
            that.$message("请输入专业名称");
            this.sbdisabled = false;
            return false;
          }
          if (that.xuelioptionsval == "") {
            that.$message("请选择学历");
            this.sbdisabled = false;
            return false;
          }
          if (that.wk_zhenshiname == "") {
            that.$message("请输入真实姓名");
            this.sbdisabled = false;
            return false;
          }
        } else if (that.tfdesign == "2") {
          //作品展
          if (that.school_zlname == "") {
            that.$message("请选择展览名称");
            this.sbdisabled = false;
            return false;
          }
        }
      }
      var zpid = "";
      if (that.$route.query.data != undefined) {
        zpid = that.$route.query.data;
      }
      that.$message("正在上传，请稍等！");
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getReviewAdd",
        method: "post",
        data: {
          title: that.wk_name, //展评标题
          word_type: that.radio_type, //1 原创设计 2 线上展览
          pay: that.radio_money, //0 不出售 1 出售
          money: that.money, //出售金额
          tfdesign: that.tfdesign, //1 毕业设计 2 作品展览
          schoolname: that.schoolid, //学校名称
          teacher: that.teachername, //指导老师
          file_url: that.uploadzipurls, //压缩包地址
          cloud_disk: that.baiduzip, //云盘
          extract_code: that.baiduzipma, //提取码
          newstext: that.textareaall, //作品介绍
          video: that.video, //上传视频        改改改改改改
          rel_type: that.rel_type, //1 年度 2 学校
          classid: "4",
          activity_one: that.zhuanyeval, //大类
          activity_two: that.zhuanyeval_sm, //小类
          d_id: that.school_zlname, //展览标题id   改改改改改改
          titlepic: that.fmimg, //封面
          atlas_img, //图集
          major: that.zhuanyename, //专业
          education_school: that.xuelioptionsval, //学历
          class_school: that.banjioptionsval, //班级
          sno_school: that.xuehaoval, //学号
          audio: that.mp3, //音频
          username: that.wk_zhenshiname, //真实姓名
          id: zpid, //编辑字段
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.$message(res.data.messages);
          this.$router.push({ path: "/Userhome/Userhl_zuopin" });
        } else {
          that.$message(res.data.messages);
          this.sbdisabled = false;
        }
      });
    },
    schoollbliclick(school, id) {
      this.school = school;
      this.schoollbtype = false;
      this.schoolhidden = 1;
      this.schoolid = id;
    },
    schoolfocus() {
      this.schoolhidden = 0;
    },
    smaxios(classid) {
      this.$axios({
        url: "/api/getClassList",
        method: "post",
        data: {
          class: classid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.zyoptionssmall = res.data.result;
          if(this.resultbj.activity_two!=undefined){
            this.zhuanyeval_sm =this.resultbj.activity_two; //小类
          };
        } else {
        }
      });
    },
    bigaxios() {
      this.$axios({
        url: "/api/getClassTopList",
        method: "post",
        data: {
          class_sort: "1",
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.zyoptions = res.data.result;
        } else {
        }
      });
    },
    baiduzipif() {
      if (this.baiduzip != "" && this.baiduzipma != "") {
        this.uploadziptype = false;
        this.uploadziptype_f = false;
        this.wangpanziptp = true;
      } else {
        this.uploadziptype = true;
        this.uploadziptype_f = false;
        this.wangpanziptp = false;
      }
    },
    wangpanziptpclick() {
      this.uploadziptype = true;
      this.uploadziptype_f = false;
      this.wangpanziptp = false;
    },
    downurl() {
      //编辑 附件赋值
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/userWorksFile",
        method: "post",
        data: {
          id: that.resultbj.id,
        },
      }).then((res) => {
        that.uploadzipurls=res.data.result.file_url;//压缩包地址
        that.baiduzip=res.data.result.cloud_disk;//云盘
        that.baiduzipma=res.data.result.extract_code;//提取码
      });
    },
    bianji(id) {
      var that = this;
      this.$axios({
        url: "/api/getReviewDetails",
        method: "post",
        data: {
          id,
        },
      }).then((res) => {
        var result = res.data.result;
        that.resultbj = result;
        console.log(result);
        that.wk_name = result.title; //展评标题
        that.radio_type = "" + result.word_type; //1 原创设计 2 线上展览
        that.radio_money = ""+result.pay; //0 不出售 1 出售
        that.tfdesign = "" + result.tfdesign; //1 毕业设计 2 作品展览
        if (that.tfdesign == 1) {
          that.bsbtn_type = "primary";
          that.sjbtn_type = "";
        } else {
          that.bsbtn_type = "";
          that.sjbtn_type = "primary";
        };
        that.school_zlname = that.resultbj.d_id;
        that.school = result.schoolname; ////学校名称
        that.teachername = result.teacher; //指导老师
        // that.uploadzipurls=result.file_url;//压缩包地址
        // that.baiduzip=result.cloud_disk;//云盘
        // that.baiduzipma=result.extract_code;//提取码
        that.textareaall = result.newstext; //作品介绍
        that.video = result.video; //上传视频
        that.rel_type = "" + result.rel_type; //1 年度 2 学校
        that.zhuanyeval = result.activity_one; //大类
        that.zhuanyeval_sm =result.activity_two; //小类
        that.fmimg = result.titlepic; //封面
        that.imghejidata = result.atlas_img.split(","); //图集
        for (var i = 0; i < that.imghejidata.length; i++) {
          that.imghejidata[i] = {
            src: that.imghejidata[i],
            name: that.imghejidata[i],
          };
        }
        console.log(that.imghejidata);
        that.zhuanyename = result.major; //专业
        that.xuelioptionsval = result.education_school; //学历
        that.xuehaoval = result.sno_school; //学号
        that.mp3 = result.audio; //音频
        that.wk_zhenshiname = result.username; //真实姓名
        that.downurl();
      });
    },
  },
  watch: {
    radio_money: function () {
      this.money = 0;
      if (this.radio_money == "1") {
        this.moneytype = true;
        this.ziptype = true;
        if(this.resultbj.money!=undefined){
            this.money =this.resultbj.money; //出售金额
          };
      } else {
        this.moneytype = false;
        this.ziptype = false;
      }
    },
    radio_type: function () {
      if (this.radio_type == "1") {
        //原创设计
        this.zhanlantype = false;
        this.yuanchuangtype = true;
        this.bishezhantype = false;
        this.shejizl = false;
      } else {
        //线上展览
        if (this.bsbtn_type == "") {
          this.zhanlantype = true;
          this.yuanchuangtype = false;
          this.shejizl = true;
          this.bishezhantype = false;
        } else {
          this.zhanlantype = true;
          this.yuanchuangtype = false;
          this.bishezhantype = true;
          this.shejizl = false;
        }
      }
    },
    bsbtn_type: function () {
      if (this.bsbtn_type == "") {
        // 作品展览
        this.bishezhantype = false;
        this.shejizl = true;
        this.yuanchuangtype = false;
      } else {
        //毕业设计展
        this.bishezhantype = true;
        this.shejizl = false;
      }
    },
    uploadzipurls: function () {
      if (this.uploadzipurls != "") {
        this.uploadziptype = false;
        this.uploadziptype_f = true;
      } else {
        this.uploadziptype = true;
        this.uploadziptype_f = false;
      }
    },
    video: function () {
      if (this.video != "") {
        this.uploadvideotype = false;
        this.uploadvideotype_f = true;
      } else {
        this.uploadvideotype = true;
        this.uploadvideotype_f = false;
      }
    },
    mp3: function () {
      if (this.mp3 != "") {
        this.uploadmp3type = false;
        this.uploadmp3type_f = true;
      } else {
        this.uploadmp3type = true;
        this.uploadmp3type_f = false;
      }
    },
    school: function () {
      var that = this;
      if (that.schoolhidden == 0) {
        if (that.school == "") {
          that.schoollbtype = false;
        } else {
          that.schoollbtype = true;
          that
            .$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/getSchoolList",
              method: "post",
              data: {
                name: that.school,
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                that.schoollbli = res.data.result;
                if (that.bianjinum == "1") {
                  that.schoollbtype = false;
                  that.bianjinum = "0";
                  for (var i = 0; i < that.schoollbli.length; i++) {
                    if (that.schoollbli[i].name == that.school) {
                      this.schoolid = that.schoollbli[i].id;
                    }
                  }
                }
              } else {
              }
            });
        }
      } else {
      }
    },
    schoolid() {
      var that = this;
      that.school_zlall = [];
      that.school_zlname = "";
      that.banjioptions = [];
      that.banjioptionsval = "";
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getSchoolDraw",
          method: "post",
          data: {
            id: that.schoolid,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.school_zlall = res.data.result.list;
            if (that.resultbj.d_id != undefined) {
              that.school_zlname = that.resultbj.d_id; //展览标题id
              that.banjioptionsval = that.resultbj.class_school; //班级
            }
          } else {
          }
        });
    },
    zhuanyeval() {
      if (this.zhuanyeval != 0) {
        this.zyoptionssmall = [];
        this.zhuanyeval_smtype = true;
        this.zhuanyeval_sm = "";
        this.smaxios(this.zhuanyeval);
      } else {
        this.zyoptionssmall = [];
        this.zhuanyeval_sm = 0;
        this.zhuanyeval_smtype = false;
      }
    },
    school_zlname() {
      this.imgallsfalse.d_id = this.school_zlname;
      this.$axios({
        url: "/api/getDrawClassList",
        method: "post",
        data: {
          d_id: this.school_zlname,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          if (res.data.result[0].class_school != "") {
            this.banjioptions = JSON.parse(res.data.result[0].class_school);
          }
          if (res.data.result[0].major_school != "") {
            this.zhuanyeoptions = JSON.parse(res.data.result[0].major_school);
          }
        } else {
        }
      });
    },
    baiduzip() {
      this.baiduzipif();
    },
    baiduzipma() {
      this.baiduzipif();
    },
    fmimg() {
      this.fengmiantext = "修改封面";
    },
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.bigaxios();
    this.$axios({
      headers: { "X-CSRF-TOKEN": that.userinfo.token },
      url: "/api/getDrawListTitle",
      method: "post",
      data: {},
    }).then((res) => {
      if (res.data.code == 1) {
        for (var i = 0; i < res.data.result.list.length; i++) {
          if (res.data.result.list[i].draw_man_type == "1") {
            that.zhanlanoptions.push(res.data.result.list[i]);
          } else if (res.data.result.list[i].draw_man_type == "2") {
            if (res.data.result.list[i].draw_userid == that.userinfo.userid) {
              that.zhanlanoptions.push(res.data.result.list[i]);
            } else {
              //个人展不属于当前登录者
            }
          }
        }
      }
    });
    var bjtypeid = that.$route.query.bjtypeid; //编辑类型 作品:'zuopin'
    var zpid = that.$route.query.data;
    if (bjtypeid != undefined) {
      this.bianji(zpid);
      this.bianjinum = "1";
    }
  },
};
</script>
